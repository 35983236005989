<template>
  <div v-if="!Object.values(isDataReady).every(v => v === true)" class="flex flex-col px-8 py-4">
    <BaseLoader class="m-auto h-5 w-5" />
  </div>
  <div v-else>
    <div class="flex flex-col space-y-2 border-b border-gray-800 px-8 py-4">
      <label class="flex items-center">
        <span class="mr-2 text-sm font-semibold capitalize text-gray-200"
          >{{ selectedAlertTag }} <span class="text-xxs text-gray-400">Optional</span></span
        >
        <span v-if="required.alertType" class="mt-0.5 text-xs text-red-400">Required</span>
      </label>
      <BaseRadio :options="alertTags" :key="alertTags" v-model="selectedAlertTag" />
      <div>
        <div class="pb-1 text-xs text-gray-400" v-if="!coinsAvailable">
          <span class="text-red-500">*</span> Selected {{ selectedAlertTag }} has No Unlock Supported Tokens.
        </div>
        <div v-if="selectedAlertTag === 'Coins'">
          <BaseSelect
            button-width-class="w-full"
            dropdown-width-class="w-full"
            placeholder="Select Coin(s)"
            :multiple="true"
            :searchable="true"
            show-selected
            :clear-option="true"
            v-model="alertData.coins"
            :options="allCoins"
          />
        </div>
        <div v-else-if="selectedAlertTag === 'Watchlist'">
          <BaseSelect
            dropdown-width-class="w-full"
            button-width-class="w-full"
            placeholder="Select Watchlist"
            :searchable="true"
            v-model="alertData.watchlist.id"
            :options="allWatchlists"
          />
        </div>
        <div v-else-if="selectedAlertTag === 'Category'">
          <BaseSelect
            button-width-class="w-full"
            dropdown-width-class="w-full"
            placeholder="Select Sector"
            :searchable="true"
            v-model="alertData.category.id"
            :options="allCategories"
          />
        </div>
        <div v-else-if="selectedAlertTag === 'Subcategory'">
          <BaseSelect
            button-width-class="w-full"
            dropdown-width-class="w-full"
            placeholder="Select SubSector"
            :searchable="true"
            v-model="alertData.subcategory.id"
            :options="allSubcategories"
          />
        </div>
      </div>
    </div>

    <div class="flex flex-col space-y-2 border-b border-gray-800 px-8 py-4">
      <label class="text-sm font-semibold text-gray-200">Filters </label>

      <div class="flex gap-2">
        <div class="space-y-2">
          <div class="flex h-8 items-center space-x-2 text-xs">In the next</div>
          <div class="flex h-8 items-center space-x-2 text-xs">
            <span class="text-xs">At least:</span>
          </div>
          <div class="flex h-8 items-center space-x-2 text-xs">
            <span class="text-xs">Of:</span>
          </div>
          <div class="flex h-8 items-center space-x-2 text-xs">
            <span class="text-xs">Types: <span class="text-xxs text-gray-400">Optional</span></span>
          </div>
          <div class="flex h-8 items-center space-x-2 text-xs">
            <span class="text-xs">Entities: <span class="text-xxs text-gray-400">Optional</span></span>
          </div>
        </div>
        <div class="space-y-1">
          <div class="flex space-x-2">
            <BaseNumberInput v-model="alertData.filters.timeframe" class="w-20 truncate"> </BaseNumberInput>
            <span class="flex items-center text-xs font-normal">days</span>
          </div>
          <BaseSelect
            v-model="alertData.filters.threshold"
            :options="thresholdOptions"
            :multiple="false"
            width-class="w-20"
            button-width-class="w-40 truncate"
          />
          <BaseSelect
            v-model="alertData.filters.thresholdMetric"
            :options="thresholdMetricsOptions"
            :multiple="false"
            width-class="w-20"
            button-width-class="w-40 truncate"
          />
          <BaseSelect
            v-model="alertData.filters.types"
            :options="typeOptions"
            multiple
            searchable
            placeholder="Filter by Types"
            width-class="w-20"
            button-width-class="w-40 truncate"
          />
          <BaseSelect
            v-model="alertData.filters.entities"
            :options="entityOptions"
            multiple
            searchable
            placeholder="Filter by Entities"
            button-width-class="w-40 truncate"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount, watch, inject, computed, nextTick, onBeforeMount } from 'vue';
import { useWatchlistStore } from '@/stores/watchlists';
import { titleize } from '@/composeables/filters';
import { useStore } from 'vuex';
import useHttp from '@/composeables/http';
import DropdownSelectCoin from '@/components/dropdown/DropdownSelectCoin.vue';
import { useCoinUnlockApi } from '@/components/coin/unlock/CoinUnlockApi';
import useEmitter from '@/composeables/emitter';

const emitter = useEmitter();
const store = useStore();
const $watchlistStore = useWatchlistStore();
const $http = useHttp();

const props = defineProps({
  alert: { type: Object, default: null }
});

//ALERT DATA
const alertData = ref(null);

onMounted(() => {
  const localStorageData = JSON.parse(window.localStorage.getItem(constructLocalStorageName('alertUnlockData')));
  alertData.value = localStorageData || {
    coins: [],
    watchlist: { id: null },
    category: { id: null },
    subcategory: { id: null },
    filters: {
      timeframe: '30',
      threshold: '0.00',
      thresholdMetric: 'avg_trading_volume_percent',
      types: [],
      entities: []
    }
  };
});

watch(
  () => alertData.value,
  () => {
    handleAlertData();
  },
  { deep: true, immediate: true }
);
function constructLocalStorageName(s) {
  let path = store.state.path
    .split('.')
    .map((s, i) => {
      return i == 0 ? s : s.charAt(0).toUpperCase() + s.slice(1);
    })
    .join('');
  s = s.charAt(0).toUpperCase() + s.slice(1);
  return `${path}${s}`;
}
function handleAlertData() {
  let config = {};
  if (alertData.value) {
    for (const [k, v] of Object.entries(alertData.value)) {
      if (['watchlist', 'category', 'subcategory'].includes(k)) {
        if (v && (v.uid != null || v.id != null)) {
          config[k] = v;
        }
      } else if ('coins' == k) {
        if (v && v.length > 0) {
          config[k] = v;
        }
      } else {
        config[k] = v;
      }
    }
    window.localStorage.setItem(constructLocalStorageName('alertUnlockData'), JSON.stringify(alertData.value));
    emitter.$emit('config:alert', { config: config });
  }
}

// RESET SELECTIONS
function clearAlertData() {
  alertData.value = {
    coins: [],
    watchlist: { id: null },
    category: { id: null },
    subcategory: { id: null },
    filters: {
      timeframe: '30',
      threshold: '0.00',
      thresholdMetric: 'avg_trading_volume_percent',
      types: [],
      entities: []
    }
  };
}
onMounted(() => {
  emitter.$on('clear:alert', () => {
    clearAlertData();
  });
  handleAlertData();
});
onBeforeUnmount(() => {
  emitter.$off('clear:alert');
});

//ALERT TAGS
const selectedAlertTag = ref('Coins');
const alertTags = ref(['Coins', 'Watchlist', 'Category', 'Subcategory']);
watch(selectedAlertTag, () => {
  window.localStorage.setItem(constructLocalStorageName('selectedAlertUnlockTag'), selectedAlertTag.value);
  alertData.value.coins = [];
  alertData.value.watchlist = { id: null };
  alertData.value.category = { id: null };
  alertData.value.subcategory = { id: null };
  coinsAvailable.value = true;
});

//COINS
const coins = computed(() => store.state.unlockCoins);
const coinDropdownOptions = ref([]);
const allCoins = ref([]);
const isDataReady = ref({ coins: false, watchlist: false, category: false });
const required = ref({ alertType: false });

//WATCHLISTS
const allWatchlists = computed(() =>
  $watchlistStore.coins.map(list => ({
    id: list.id,
    label: list.name,
    text: list.name
  }))
);
const watchlistsReady = computed(() => $watchlistStore.watchlistLoader.coins);

watch(watchlistsReady, () => {
  if (watchlistsReady.value) isDataReady.value.watchlist = true;
});

onMounted(() => {
  isDataReady.value.watchlist = watchlistsReady.value;
});

watch(
  () => alertData.value?.watchlist?.id,
  () => {
    let watchlist = allWatchlists.value.find(w => w.id == alertData.value.watchlist.id);
    alertData.value.watchlist = watchlist ? { id: watchlist.id, name: watchlist.label } : { id: null };
  }
);

//CATEGORIES
const allCategories = ref([]);
const allSubcategories = ref([]);
async function fetchCategories() {
  allCategories.value = store.state.categories.coin
    .filter(cat => cat.id !== 0)
    .map(cat => ({ id: cat.id, label: cat.name, text: cat.name }));

  store.state.categories.coin_sub_categories
    .filter(sub => sub.id !== 0)
    .forEach(sub => {
      let associatedCategory = store.state.categories.coin.find(item => item.id == sub.category_id);
      allSubcategories.value.push({
        id: sub.id,
        html: `${sub?.name}<span class='block text-xs text-gray-400'>${associatedCategory?.name}</span>`,
        label: sub?.name
      });
    });

  allSubcategories.value = allSubcategories.value.sort((a, b) => (a.label > b.label ? 1 : -1));
  isDataReady.value.category = true;
}

const coinsAvailable = ref(true);
onMounted(() => {
  fetchCategories();
  emitter.$on('update:coins-availability', data => {
    coinsAvailable.value = data.coinsAvailable;
  });
});

// Edit Alert
function editData() {
  if (props.alert) {
    selectedAlertTag.value = Object.keys(props.alert.config)
      .map(x => titleize(x))
      .find(x => alertTags.value.includes(x));
    nextTick(() => {
      if (props.alert.config?.coins) {
        alertData.value.coins = props.alert.config.coins;
      } else if (props.alert.config?.watchlist) {
        alertData.value.watchlist.id = props.alert.config.watchlist.id;
      } else if (props.alert.config?.category) {
        alertData.value.category = props.alert.config.category;
      } else if (props.alert.config?.subcategory) {
        alertData.value.subcategory = props.alert.config.subcategory;
      }
      if (props.alert.config?.filters) {
        alertData.value.filters = props.alert.config?.filters;
      }
    });
  }
}
watch(
  () => isDataReady.value,
  val => {
    editData();
  },
  { deep: true }
);
watch(
  () => coins.value,
  () => {
    const baseOptions = [{ label: 'Deselect All', id: 0, disabled: true }];
    if (coins.value) {
      allCoins.value = coins.value.map(coin => {
        return {
          id: coin?.coin_uid,
          label: coin?.name,
          ticker: coin?.ticker
        };
      });
      coinDropdownOptions.value = baseOptions.concat(allCoins);
      isDataReady.value.coins = true;
    }
  },
  { immediate: true }
);
watch(
  () => alertData.value?.coins,
  (newVal, oldVal) => {
    coinDropdownOptions.value[0].disabled = newVal.length == 0;
    if (newVal.includes(0)) {
      alertData.value.coins = [];
    }
  }
);

// AVAILABLE FILTERS
const multipleCoins = ref(alert.value?.config?.coins?.length > 1 || true);
const thresholdOptions = [
  { label: 'Any', id: '0.00' },
  { label: '0.1%', id: '0.001' },
  { label: '1%', id: '0.01' },
  { label: '2%', id: '0.02' },
  { label: '4%', id: '0.04' },
  { label: '6%', id: '0.06' },
  { label: '8%', id: '0.08' },
  { label: '10%', id: '0.10' },
  { label: '25%', id: '0.25' },
  { label: '50%', id: '0.50' }
];

const thresholdMetricsOptions = [
  { label: 'Avg. Trading Volume', id: 'avg_trading_volume_percent' },
  { label: 'Market Cap', id: 'market_cap_percent' },
  { label: 'Total Supply', id: 'supply_percent' }
];

const unlockApi = useCoinUnlockApi();
const typeOptions = ref([]);
const entityOptions = ref([]);

onBeforeMount(async () => {
  const holders = await unlockApi.getHolders();

  const entitySet = new Set();
  const typeSet = new Set();

  holders.forEach(holder => {
    if (multipleCoins.value) {
      entitySet.add(holder.entity);
      typeSet.add(holder.type);
    }
  });
  entityOptions.value = Array.from(entitySet).map(entity => {
    return {
      label: entity,
      id: entity
    };
  });

  typeOptions.value = Array.from(typeSet).map(type => {
    return {
      label: type,
      id: type
    };
  });
});
</script>
